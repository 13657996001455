// Cleaning up extra characters as a result of contanation
export function prepareUrl(...args: string[]) {
  const arr = args.map((i, idx) => {
    if (idx === 0 && i.indexOf("?") === -1) {
      return `${i}?`;
    }

    return i;
  });

  let url = arr.join("&");

  const t = url.replaceAll(/\?+/g, "?").split("?");

  if (t.length > 2) {
    console.error("prepareUrl many '?'", args);
  }

  const origin = t[0];
  let path = t[1]
    .replace(/^[&;:,]+/, "")
    .replace(/[&;:,]+$/, "")
    .replace(/[;:,]+&+/g, "&")
    .replace(/&+[;:,]+/g, "&")
    .replace(/[;:,]{2,}/g, "");

  return !path ? origin : `${origin}?${path}`;
}

export const prepareQueryParamsToString = (params: { [param: string]: any } = {}) => {
  const t: { [param: string]: any } = {};

  Object.keys(params).forEach((name) => {
    if (typeof params[name] !== "undefined" && params[name] !== null && params[name] !== '') {
      t[name] = `${params[name]}`;
    }
  });

  return Object.keys(t)
    .map((name) => `${name}=${params[name]}`)
    .join("&");
};

export const getFilenameFromUrl = (url: string) => {
  try {
    return url.split('/').slice(-1)[0] || url;
  } catch (err) {
    console.error('Error getFilenameFromUrl', url);
  }

  return url;
}
